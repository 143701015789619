import { Component } from 'react';
import log from './event_log';

export default class ChangeEvent extends Component {

  constructor(...props) {
    super(...props);

    this.state = {
      fullscreenchange: "null",
      mozfullscreenchange: "null",
      webkitfullscreenchange: "null",
      isFullscreen: false,
    };

    ['', 'moz', 'webkit'].forEach(prefix => {
      const ev = `${prefix}fullscreenchange`;
      log('addEventListener', ev);
      document.addEventListener(ev, (e) => {
        log('fired', ev);
        this.setState({
          [ev]: `fired @ ${Date.now()}`,
        });
      }, false);
    })
  }


  render() {
    const { state } = this;
    return (
      <div className='card text-dark bg-light mb-3'>
        <div className='card-header'>Events:</div>
        <div className='card-body'>
          <p className='card-text'>Status of monitoring event on <code>document</code></p>
          <ul className='list-group'>
            <li className='list-group-item'>fullscreenchange: <i>{ state.fullscreenchange }</i></li>
            <li className='list-group-item'>mozfullscreenchange: <i>{ state.mozfullscreenchange }</i></li>
            <li className='list-group-item'>webkitfullscreenchange: <i>{ state.webkitfullscreenchange }</i></li>
          </ul>
        </div>
      </div>
    )
  }
}