import { detect } from 'detect-browser';

import './App.css';
import ChangeEvent from './ChangeEvent';
import Enter from './Enter';
import Exit from './Exit';
import Status from './Status';
import log from './event_log';

function App() {
  const browser = detect();
  log('browser', browser);
  log('userAgent', navigator.userAgent);
  return (
    <div className="container-md">
      <div className="row mt-3">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h4 className="car-title">@aw/video-util::fullscreen</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col"><Enter /></div>
        <div className="col"><Exit /></div>
      </div>
      <div className="row mt-3">
        <div className="col"><Status /></div>
        <div className="col"><ChangeEvent /></div>
      </div>
    </div>
  );
}

export default App;
