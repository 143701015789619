import { Component } from 'react';
import log from './event_log';

export default class EnterFullscreen extends Component {
  constructor(...props) {
    super(...props);
    // var openers = [
    //   ':requestFullscreen',
    //   'moz:RequestFullScreen',
    //   'webkit:RequestFullScreen',
    // ];
    this.state = {};

  }

  componentDidMount() {
    const div = document.createElement('div');
    const obj = {};
    ['requestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullScreen'].forEach(method => {
      log('enter', method, typeof div[method], div[method]);
      obj[method] = typeof div[method];
    });
    this.setState(obj);
  }

  render() {
    const { state } = this;
    const keys = Object.keys(state);
    return (
      <div className="card text-dark bg-light mb-3">
        <div className='card-header'>Enter:</div>
        <div className='card-body'>
          <p className='card-text'>Function available on <code>&lt;div&gt;</code> elements</p>
          <ul className="list-group">{
            keys.map((k, i) => {
              const cname = state[k] === 'function' ? 'info' : 'warning';
              const classes = `list-group-item list-group-item-${cname}`;
              return (<li className={ classes } key={ i }>{ k }: <i>{ state[k] }</i></li>);
            })
          }</ul>
        </div>
      </div>
    )
  }

}