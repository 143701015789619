import { Component } from 'react';

export default class StatusListener extends Component {
  constructor(...props) {
    super(...props);
    this.state = {};
  }

  startPolling() {
    this.timeout = setTimeout(() => {
      this.setState({
        fullScreen: document.fullScreen,
        mozFullScreen: document.mozFullScreen,
        webkitCurrentFullScreenElement: document.webkitCurrentFullScreenElement,
        fullscreenElement: document.fullscreenElement,
        webkitIsFullScreen: document.webkitIsFullScreen,
        heightDiff: window.outerHeight - window.innerHeight <= 1,
      }, () => {
        this.startPolling();
      });
      // document.fullScreen
      // || document.mozFullScreen
      // || document.webkitIsFullScreen
      // || document.webkitCurrentFullScreenElement
      // || document.fullscreenElement
      // || (window.outerHeight - window.innerHeight <= 1))
    }, 1000);
  }

  componentDidMount() {
    this.startPolling();
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    const { state } = this;
    const keys = Object.keys(state);
    return (
      <div className="card text-dark bg-light mb-3">
        <div className="card-header">Status:</div>
        <div className="card-body">
          <p className="card-text">Monitoring various <code>fullscreen</code> indicators</p>
          <ul className="list-group">{
            keys.map((k, i) => {
              const truth = state[k] ? "info" : "warning";
              const className = `list-group-item list-group-item-${ truth }`;
              return <li className={ className }>{ k }: <i>{ state[k] ? "true" : "null" }</i></li>
            })
          }</ul>
        </div>
      </div>
    );
  }

}