import { Component } from 'react';

export default class ExitFullscreen extends Component {
  constructor(...props) {
    super(...props);
    this.state = {};

  }

  componentDidMount() {
    const obj = {};
    ['exitFullscreen', 'webkitExitFullscreen', 'mozCancelFullScreen'].forEach(method => {
      obj[method] = typeof document[method];
    });
    this.setState(obj);
  }

  render() {
    const { state } = this;
    const keys = Object.keys(state);
    return (
      <div className="card text-dark bg-light mb-3">
        <div className='card-header'>Exit:</div>
        <div className='card-body'>
          <p className='card-text'>How to programatically exit <code>fullscreen</code></p>
          <ul className="list-group">{
            keys.map((k, i) => {
              const cname = state[k] === 'function' ? 'info' : 'warning';
              const classes = `list-group-item list-group-item-${cname}`;
              return (<li className={ classes } key={ i }>{ k }: <i>{ state[k] }</i></li>);
            })
          }</ul>
        </div>
      </div>
    )
  }

}